import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store"
import useJwt from '@/auth/jwt/useJwt'

import { get } from "lodash"
import {
  MUTATE_SETTINGS,
  MUTATE_USER_DATA,
  MUTATE_LOGIN_STATUS,
  MUTATE_CLIENT_GROUP,
  MUTATE_PHONE_VERIFICATION_STATUS,
} from "@/store/config/mutation-keys"

// Routes
import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate, checkLoginTokenStatus, getMaintenanceSettings, getSystemMessageSettings } from '@/libs/acl/routeProtection'

import userRoutes from "./routes/user";
import publicRoutes from "./routes/public";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...publicRoutes,
    ...userRoutes,
    {
      path: '*',
      name: 'error-404',
      component: () => import('@/pages/user/auth/error-404'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        roles: ["admin", "user", "anonymous"]
      },
    },
  ],
})


router.beforeEach(async (to, from, next) => {
  try {
    const toPathHasMaintenanceMode = get(to, 'meta.hasMaintenanceMode', false);

    let isLoggedIn = isUserLoggedIn();

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const { authorized, user, settings } = await checkLoginTokenStatus()
      if (authorized) {
        isLoggedIn = true;
        store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
        store.commit(`auth/${MUTATE_USER_DATA}`, user);
        store.commit(`auth/${MUTATE_SETTINGS}`, settings);
      }
    }

    if (!isLoggedIn) {
      await getSystemMessageSettings();
    }

    /**
     * password reset route handle
     */
    const { token: hasAccountOrPasswordConfirmationToken } = to.query;
    if (hasAccountOrPasswordConfirmationToken && to.name === "auth-reset-password") {
      // console.log("---> has password reset token");
      const { token: passwordResetToken } = to.query;
      if (!passwordResetToken) {
        store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
        return next({ name: "app-root" });
      }

      try {
        useJwt.authService.setPasswordResetToken(passwordResetToken);
        await useJwt.authService.confirmTokenValidity("password_reset_token");
        return next();
      } catch (error) {
        return next({ name: "auth-forgot-password", query: { error: "Password reset token has expired" } });
      }
    }

    const app_in_maintenance = await getMaintenanceSettings();
    if (app_in_maintenance && toPathHasMaintenanceMode) {
      return next({ name: 'misc-under-maintenance' });
    }

    if (!app_in_maintenance && to.name === 'misc-under-maintenance') {
      return next("/");
    }

    /**
     * this validation is for routes that only allow access if certain prop is not available.
     */
    const supportedRoutesWithPropValidations = [
      "auth-verify-phone",
      "onboarding"
    ];
    if (supportedRoutesWithPropValidations.includes(to.name) && isLoggedIn) {
      const { authorized, user } = await checkLoginTokenStatus()
      // console.log("---> authorized", authorized);

      if (!authorized) {
        return next({ name: 'app-root' });
      }

      const phoneNumberVerified = get(user, 'meta.phone_verified', false);
      const { has_completed_basic_requirement: basicRequirementCompleted = false, user_type: userType, has_completed_additional_requirement: additionalRequirementsCompleted = false, country } = user;


      // redirects if phone number already verified
      if (userType === "user" && phoneNumberVerified && to.name === "auth-verify-phone") {
        return next("/");
      }

      if (userType === "user" && basicRequirementCompleted && to.name === "onboarding") {
        if (country === "Ghana") {
          if (additionalRequirementsCompleted) {
            return next("/");
          }
        } else {
          return next("/");
        }
      }
    }


    /**
     * path requires authentication but user is logged in.
     */
    const toPathRequiresAuth = get(to, 'meta.requireAuth', false);
    if (toPathRequiresAuth && isLoggedIn) {
      // console.log("---> requires authentication and is logged in");
      const { authorized, user, settings, client_group } = await checkLoginTokenStatus()
      // console.log("---> authorized", authorized);
      const in_maintenance_mode = get(settings, 'general_settings.enable_maintenance_mode', false)

      if (user.user_type !== "admin" && in_maintenance_mode) {
        return next({ name: 'misc-under-maintenance' });
      }

      if (!in_maintenance_mode && to.name === 'misc-under-maintenance') {
        return next("/");
      }

      if (!authorized) {
        return next({ name: 'app-root' });
      }

      store.commit(`auth/${MUTATE_USER_DATA}`, user);
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
      store.commit(`auth/${MUTATE_SETTINGS}`, settings);
      store.commit(`auth/${MUTATE_CLIENT_GROUP}`, client_group);

      const phoneVerificationStatus = get(user, 'meta.phone_verified', false);
      const requirePhoneVerification = get(settings, 'security_settings.require_client_phone_verification', false);
      const { has_completed_basic_requirement: basicRequirementCompleted = false, has_completed_additional_requirement: additionalRequirementsCompleted = false, country } = user;

      store.commit(`auth/${MUTATE_PHONE_VERIFICATION_STATUS}`, phoneVerificationStatus);

      console.log("---> basicRequirementCompleted", basicRequirementCompleted);
      console.log("---> additionalRequirementsCompleted", additionalRequirementsCompleted);



      if (!phoneVerificationStatus && requirePhoneVerification) {
        // console.log("---> requires phone verification but phone not verified");
        store.commit(`auth/${MUTATE_PHONE_VERIFICATION_STATUS}`, false);
        try {
          if (to.name !== "auth-verify-phone") {
            const response = await useJwt.authService.requestPhoneVerification();

            const { data } = response.data;

            if (data.sms_verification_code_timestamp) {
              useJwt.authService.setPhoneVerificationTimestamp(data.sms_verification_code_timestamp);
            }

            if (data.phone_verification_token) {
              useJwt.authService.setPhoneVerificationToken(data.phone_verification_token);
            }

            return next({ name: "auth-verify-phone" });
          }

          return next();
        } catch (error) {
          if (to.name !== "auth-verify-phone") {
            return next({ name: "auth-verify-phone" });
          }

          return next();
        }
      }


      if (!basicRequirementCompleted || (country === "Ghana" && !additionalRequirementsCompleted)) {
        const onboarding_screens = ["onboarding"];

        if (!onboarding_screens.includes(to.name)) {
          return next({ name: "onboarding" });
        }

        return next();
      }

      // const daysBeforePasswordExpire = user.days_before_password_expire;

      // if (daysBeforePasswordExpire <= 0 && to.name !== 'security-password-change') {
      //   return next({ name: 'security-password-change' })
      // }

      /**
       * redirect user to dashboard.
       */
      if (to.path === "/") {
        const { name } = getHomeRouteForLoggedInUser(user.user_type);

        const currentUserHomeRoute = router.resolve({ name });
        if (!currentUserHomeRoute) {
          return next();
        }

        if (canNavigate(currentUserHomeRoute.route, user.user_type)) {
          return next({ name, query: get(to, 'query') });
        }

        return next({ name: 'misc-not-authorized' })
      }


      // check is user has access to route.
      if (canNavigate(to, user.user_type)) {
        // console.log("---> all good proceed");
        return next();
      }

      // console.log("---> not allowed");
      return next({ name: 'misc-not-authorized' });
    }

    /**
     * path requires authentication but user is not logged in.
     */
    if (toPathRequiresAuth && !isLoggedIn) {
      // console.log("---> requires authentication and is not logged in");
      return next({ name: 'app-root' });
    }

    const redirectIfLoggedIn = get(to, 'meta.redirectIfLoggedIn', false);
    if (!toPathRequiresAuth && isLoggedIn && redirectIfLoggedIn) {
      // console.log("---> requires no authentication with redirect and is logged in");
      const { authorized, user, settings, client_group } = await checkLoginTokenStatus()

      const in_maintenance_mode = get(settings, 'general_settings.enable_maintenance_mode', false)
      if (user.user_type !== "admin" && in_maintenance_mode) {
        if (to.name === 'misc-under-maintenance') {
          return next();
        }
        return next({ name: 'misc-under-maintenance' });
      }

      if (!in_maintenance_mode && to.name === 'misc-under-maintenance') {
        return next("/");
      }

      console.log("---> authorized", authorized);
      if (authorized) {
        store.commit(`auth/${MUTATE_USER_DATA}`, user);
        store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
        store.commit(`auth/${MUTATE_SETTINGS}`, settings);
        store.commit(`auth/${MUTATE_CLIENT_GROUP}`, client_group);

        const { name } = getHomeRouteForLoggedInUser(user.user_type);

        const currentUserHomeRoute = router.resolve({ name });
        if (!currentUserHomeRoute) {
          return next();
        }

        if (canNavigate(currentUserHomeRoute.route, user.user_type)) {
          return next({ name, query: get(to, "query") });
        }

        return next({ name: 'misc-not-authorized' })
      }

      // proceed to intended destination because user is not logged in.
      // here user is only redirect if they are logged in.
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false);
      return next();
    }

    // console.log("---> requires no authentication and not logged in");
    return next()
  } catch (error) {
    console.log('middleware error', error)
    useJwt.authService.clearAccessToken();

    // console.log("Login Error", error.message);
    if (!to.name !== "auth-login") {
      store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
      next({ name: "app-root" });
    }
    return next();
  }
})


router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // eslint-disable-next-line no-undef
    NProgress.start()
  }
  next()
})

router.afterEach(async (to) => {
  // Complete the animation of the route progress bar.
  // eslint-disable-next-line no-undef
  NProgress.done()

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  try {
    const requireAuth = get(to, 'meta.requireAuth');
    const hasLogPageName = get(to, 'meta.logPageName');
    const isLoggedIn = isUserLoggedIn();

    if (requireAuth && hasLogPageName && isLoggedIn) {
      await useJwt.authService.logPageAccess({
        route: to.path,
        comment: hasLogPageName
      });
    }
  } catch (error) {
    //
  }
});


export default router
