export default class SettingsJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  update(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminSettingsEndpoint}/${id}`, payload)
  }
}
