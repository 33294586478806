export default class CarBookingJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  book(payload) {
    return this.axiosIns.post("car-bookings", payload);
  }

  fetch(params) {
    return this.axiosIns.get("car-bookings", { params });
  }

  get(id) {
    return this.axiosIns.get(`car-bookings/${id}`);
  }

  cancel(id) {
    return this.axiosIns.post(`car-bookings/${id}/cancel`);
  }

  verifyPayment(params) {
    return this.axiosIns.get("car-bookings/payments/verify", { params });
  }

  cancelPayment(txref) {
    return this.axiosIns.post(`car-bookings/payments/${txref}/cancel`, {});
  }

  updateLocations(id, payload) {
    return this.axiosIns.put(`car-bookings/${id}/booking-locations`, payload);
  }

  payRemainder(id) {
    return this.axiosIns.post(`car-bookings/${id}/payments/complete`, {});
  }

  getReservedDates(car_id) {
    return this.axiosIns.get(`car-bookings/${car_id}/reserved-dates`);
  }
}
