export default [
  {
    path: "/my-rentals",
    name: "my-rentals",
    meta: {
      action: "read",
      layout: "horizontal",
      isPublicRoute: false,
      usePublicFooter: true,
      hasMaintenanceMode: true,
      contentClass: "m-0 pl-0 pr-0 pt-5x",
    },
    component: () => import("@/pages/user/main/my-rentals"),
  },
  {
    path: "/history",
    name: "history",
    meta: {
      action: "read",
      layout: "horizontal",
      isPublicRoute: false,
      usePublicFooter: true,
      hasMaintenanceMode: true,
      contentClass: "m-0 pl-0 pr-0 pt-5x",
    },
    component: () => import("@/pages/user/main/history"),
  },
  {
    path: "/favorites",
    name: "favorites",
    meta: {
      action: "read",
      layout: "horizontal",
      usePublicFooter: true,
      isPublicRoute: false,
      hasMaintenanceMode: true,
      contentClass: "m-0 pl-0 pr-0 pt-5x",
    },
    component: () => import("@/pages/user/main/favorites"),
  },
  {
    path: "/personal-info",
    name: "personal-info",
    meta: {
      action: "read",
      layout: "horizontal",
      usePublicFooter: true,
      isPublicRoute: false,
      hasMaintenanceMode: true,
      contentClass: "m-0 pl-0 pr-0 pt-5x",
    },
    component: () => import("@/pages/user/main/personal-info"),
  },
  {
    path: '/maintenance-mode',
    name: 'misc-under-maintenance',
    component: () => import('@/pages/user/auth/maintenance'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["user"],
      redirectIfLoggedIn: true,
      usePublicFooter: true,
      contentClass: "m-0 pl-0 pr-0 pt-5x",
    },
  },
];
