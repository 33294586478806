export default class ResourceActionJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  create(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action`, payload);
  }

  fetch(resource_action_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`)
  }

  update(resource_action_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`, payload)
  }

  delete(resource_action_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action/${resource_action_id}`)
  }

  list(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resource-action`, {
      params
    })
  }
}
