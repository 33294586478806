export default class PermissionJwtServicesSchema {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/schema`, {
      params
    })
  }
}
