export default class AdminCars {
  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminCarsEndpoint, {
      params
    })
  }

  fetch(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}`, {})
  }

  export(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCarsEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  create(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCarsEndpoint}`, payload)
  }

  update(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}`, payload, {})
  }

  delete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}`, {})
  }

  publish(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}/publish`, {})
  }

  unpublish(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}/unpublish`, {})
  }

  markAsBestOffer(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}/mark-as-best-offer`, {})
  }

  removeBestOffer(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}/remove-best-offer`, {})
  }

  createFeaturedImage(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}/featured-image`, payload)
  }

  deleteFeaturedImage(id){
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminCarsEndpoint}/${id}/remove-featured-image`, {})
  }
}
