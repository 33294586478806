export default class ResourceJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources`, {
      params
    })
  }

  create(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources`, payload);
  }

  fetch(resource_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`)
  }

  update(resource_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`, payload)
  }

  deactivate(resource_id, payload) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/resources/${resource_id}`, payload)
  }
}
