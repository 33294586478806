export default class AdminCarTypes {
  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminCarTypesEndpoint, {
      params
    })
  }

  fetch(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCarTypesEndpoint}/${id}`, {})
  }

  export(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCarTypesEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  create(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCarTypesEndpoint}`, payload)
  }

  update(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarTypesEndpoint}/${id}`, payload, {})
  }

  delete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminCarTypesEndpoint}/${id}`, {})
  }
}
