import { get } from "lodash"
import { format, formatDistance } from "date-fns"

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

/**
 * This is just enhancement over Object.extend [Gives deep extend]
 * @param {target} a Object which contains values to be overridden
 * @param {source} b Object which contains values to override
 */
// export const objectExtend = (a, b) => {
//   // Don't touch 'null' or 'undefined' objects.
//   if (a == null || b == null) {
//     return a
//   }

//   Object.keys(b).forEach(key => {
//     if (Object.prototype.toString.call(b[key]) === '[object Object]') {
//       if (Object.prototype.toString.call(a[key]) !== '[object Object]') {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = b[key]
//       } else {
//         // eslint-disable-next-line no-param-reassign
//         a[key] = objectExtend(a[key], b[key])
//       }
//     } else {
//       // eslint-disable-next-line no-param-reassign
//       a[key] = b[key]
//     }
//   })

//   return a
// }

export const formatDuration = (start, end) => {
  const start_date = new Date(start)
  const end_date = new Date(end)

  let startFormat = format(start_date, "dd MMMM, yyyy")
  let endFormat = format(end_date, "dd MMMM, yyyy")

  const startMonth = start_date.getMonth()
  const endMonth = end_date.getMonth()
  const startYear = start_date.getFullYear();
  const endYear = end_date.getFullYear();

  if (startMonth === endMonth && startYear === endYear) {
    startFormat = format(start_date, 'do')
    endFormat = format(end_date, 'do')
    const monthFormat = format(end_date, 'MMMM');

    return `${startFormat} - ${endFormat}, ${monthFormat} ${endYear}`
  }

  return `${startFormat} - ${endFormat}`
}

export const maskPhoneNumber = (phoneNumber) => {
  const masked = phoneNumber.replace(/[\w\W]/g, "*");
  return `${masked.substr(0, phoneNumber.length - 4)}${phoneNumber.substr(phoneNumber.length - 4)}`;
}

export const formatDate = (date, formatStr = "dd-MM-yyyy") => {
  return format(new Date(date), formatStr);
}

export const formatDateDistance = (date) => {
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export const formatTime = (timeStr, formatStr = "h:mm a") => {
  const today = new Date()
  const d = new Date(today.getFullYear(), today.getMonth(), today.getDay(), ...timeStr.split(':'))
  return format(d, formatStr);
}
export const formatMoney = (
  amount,
  fraction = 2
) => {
  if (!amount) {
    return 0.00
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: fraction,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
}

export const calculatePercentage = (percent, value) => {
  return (percent / 100) * value
}

export function computeLoanCostAndPenalCharge(loan) {
  const penal_charges = loan.penal_charges || [];
  const total_charge = penal_charges.reduce((accm, penal_charge) => accm + penal_charge.charge, 0);
  return loan.total_loan_cost + total_charge;
}

export function getPasswordErrorMessage(password_settings) {
  if (!password_settings.enable_password_complexity_check) {
    return "";
  }
  const minimumLength = get(password_settings, "minimum_length", 8)

  const messageTexts = [];

  if (get(password_settings, "include_upper_and_lower_cased_characters", true)) {
    messageTexts.push("one uppercase");
    messageTexts.push("one lowercase");
  }
  if (get(password_settings, "include_special_character", true)) {
    messageTexts.push("one special character");
  }
  if (get(password_settings, "include_number", true)) {
    messageTexts.push("one digit");
  }

  let messageString = "";
  messageTexts.forEach(text => {
    if (text === messageTexts[messageTexts.length - 1]) {
      messageString = messageString + " and " + text
    } else {
      messageString = messageString + ", " + text
    }
  })

  return `Your password must contain at least ${minimumLength} characters${messageString}`;
}

export function base64toBlob(data) {
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

  const bytes = atob(base64WithoutPrefix);
  // eslint-disable-next-line prefer-destructuring
  let length = bytes.length;
  const out = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
}

export function captureMongoId(source) {
  const regex = /([0-9a-fA-F]{24})/; // Matches "/ followed by 24-character hex string"
  const match = source.match(regex);
  if (match) {
    return match[1]; // Group 1 captures the actual mongoID
  }
  return null; // Or throw an error if desired
}
