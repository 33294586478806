import Logs from './logs';
import Users from './users';
import Customers from './customers';
import Search from './search';
import Policy from './policy';
import Resource from './resource';
import Settings from './settings';
import Dashboard from './dashboard';
import UserRoles from './user-roles';
import AuditTrail from './audit-trail';
import SampleCSVFiles from './sample-csv-file';
import ResourceActions from './resource-actions';
import PermissionSchema from './permission-schema';

import AdminCars from './cars';
import AdminDrivers from './drivers';
import AdminCarMakes from './car-makes';
import AdminCarTypes from './car-types';
import AdminCarBrands from './car-brands';
import AdminCarImages from './car-images';
import AdminCarPricing from './car-pricing';
import AdminCarBookings from './car-bookings';
import AdminCarFeatures from './car-features';
import AdminContactEmails from './contact-emails';
import AdminRefundRequest from "./refund-requests";
import AdminBookingLocations from './booking-locations';
import AdminReportedProblems from './reported-problems';

export default class MainJwtServices {
  axiosIns = null;

  jwtConfig = null;

  users = null;

  search = null;

  policy = null;

  settings = null;

  resource = null;

  userRoles = null;

  resourceActions = null;

  permissionSchema = null;

  logs = null;

  auditTrail = null;

  sampleCSVFiles = null;

  carTypes = null;

  customers = null;

  users = null;

  carFeatures = null;

  carBrands = null;

  carImages = null;

  cars = null;

  bookingLocations = null;

  carBookings = null;

  carPricing = null;

  contactEmails = null;

  drivers = null;

  carMakes = null;

  reportedProblems = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;

    this.logs = new Logs(axiosIns, jwtConfig);
    this.customers = new Customers(axiosIns, jwtConfig);
    this.search = new Search(axiosIns, jwtConfig);
    this.policy = new Policy(axiosIns, jwtConfig);
    this.settings = new Settings(axiosIns, jwtConfig);
    this.resource = new Resource(axiosIns, jwtConfig);
    this.userRoles = new UserRoles(axiosIns, jwtConfig);
    this.dashboard = new Dashboard(axiosIns, jwtConfig);
    this.users = new Users(axiosIns, jwtConfig);
    this.auditTrail = new AuditTrail(axiosIns, jwtConfig);
    this.sampleCSVFiles = new SampleCSVFiles(axiosIns, jwtConfig);
    this.resourceActions = new ResourceActions(axiosIns, jwtConfig);
    this.permissionSchema = new PermissionSchema(axiosIns, jwtConfig);

    this.cars = new AdminCars(axiosIns, jwtConfig);
    this.drivers = new AdminDrivers(axiosIns, jwtConfig);
    this.carTypes = new AdminCarTypes(axiosIns, jwtConfig);
    this.carMakes = new AdminCarMakes(axiosIns, jwtConfig);
    this.carBrands = new AdminCarBrands(axiosIns, jwtConfig);
    this.carImages = new AdminCarImages(axiosIns, jwtConfig);
    this.carPricing = new AdminCarPricing(axiosIns, jwtConfig);
    this.carBookings = new AdminCarBookings(axiosIns, jwtConfig);
    this.carFeatures = new AdminCarFeatures(axiosIns, jwtConfig);
    this.refundRequest = new AdminRefundRequest(axiosIns, jwtConfig);
    this.contactEmails = new AdminContactEmails(axiosIns, jwtConfig);
    this.reportedProblems = new AdminReportedProblems(axiosIns, jwtConfig);
    this.bookingLocations = new AdminBookingLocations(axiosIns, jwtConfig);
  }
}
