export default class ContactEmailsJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  create(payload = {}) {
    return this.axiosIns.post(`contact-emails`, payload)
  }
}
