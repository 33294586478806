export default class MiscellaneousJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchFilters(params) {
    return this.axiosIns.get("miscellaneous/filters", {
      params,
    });
  }

  fetchBookingLocations(params) {
    return this.axiosIns.get("booking-locations", {
      params,
    });
  }
}
