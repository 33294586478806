export default class AdminCarBookings {
  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminCarBookingsEndpoint, {
      params
    })
  }

  fetch(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/${id}`, {})
  }

  export(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  create(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCarBookingsEndpoint}`, payload)
  }

  update(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/${id}`, payload, {})
  }

  markAsPickedUp(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/${id}/picked-up`, payload, {})
  }

  markAsReturned(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/${id}/returned`, payload, {})
  }

  approve(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/${id}/approve`, {})
  }

  decline(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/${id}/decline`, {})
  }

  downloadReportPDF(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/download-pdf`, payload)
  }

  addChauffeur(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCarBookingsEndpoint}/${id}/add-chauffeur`, payload, {})
  }
}
