export default class CarsJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetch(params) {
    return this.axiosIns.get("cars", { params });
  }

  get(id, params) {
    return this.axiosIns.get(`cars/${id}`, { params });
  }

  userGet(id) {
    return this.axiosIns.get(`cars/${id}/user-view`);
  }
}
