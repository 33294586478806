export default class SearchJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  search(search_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminSearchEndpoint}/${search_type}/autocomplete`, {
      params
    })
  }
}
