export default class LogJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(log_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminLogsEndpoint}/${log_type}`, {
      params
    })
  }

  export(log_type, params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminLogsEndpoint}/${log_type}/export`, {
      params,
      responseType: "blob"
    });
  }
}
