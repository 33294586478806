export default class CarViewHistoryJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get("cars-viewed", { params });
  }

  add(id) {
    return this.axiosIns.put(`cars-viewed/${id}`);
  }

  remove(id) {
    return this.axiosIns.delete(`cars-viewed/${id}`);
  }
}
