export default class BestOfferJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get("best-offers", { params });
  }
}
