export default class usersJwtService {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  /** Admin Users */
  create(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.usersEndpoint}`, payload)
  }

  list(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.usersEndpoint}`, {
      params
    })
  }

  fetch(user_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.usersEndpoint}/${user_id}`)
  }

  update(user_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.usersEndpoint}/${user_id}`, payload)
  }

  resendAdminAccountConfirmationEmail(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.usersEndpoint}/${user_id}/account/confirmation/resend`)
  }

  resetAdminUserMFA(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.usersEndpoint}/${user_id}/account/mfa/reset`)
  }

  // /** Users */

  // updateUser(user_id, payload = {}) {
  //   return this.axiosIns.put(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/update`, payload, {
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     }
  //   })
  // }

  // deactivateUser(user_id) {
  //   return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/deactivate`, {})
  // }

  // approveUser(user_id) {
  //   return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/approve`, {})
  // }

  // deleteUserAvatar(user_id) {
  //   return this.axiosIns.delete(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/avatar`, {})
  // }

  // resendAccountConfirmationEmail(user_id) {
  //   return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/account/confirmation/resend`)
  // }

  // resetUserMFA(user_id) {
  //   return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/account/mfa/reset`)
  // }

  // fetchUsers(params) {
  //   return this.axiosIns.get(this.jwtConfig.admin.adminCustomersEndpoint, {
  //     params
  //   })
  // }

  // fetchUser(user_id) {
  //   return this.axiosIns.get(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}`, {})
  // }
}
