export default class AdminReportedProblems {
  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminReportedProblemsEndpoint, {
      params
    })
  }

  fetch(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReportedProblemsEndpoint}/${id}`, {})
  }

  export(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminReportedProblemsEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  resolve(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminReportedProblemsEndpoint}/${id}/mark-as-resolved`, payload, {})
  }
}
