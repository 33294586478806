export default class PolicyJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy`, { params })
  }

  create(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy`, payload)
  }

  update(policy_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`, payload)
  }

  fetch(policy_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`)
  }

  deleteAdminPolicy(policy_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/policy/${policy_id}`)
  }
}
