export default [
  {
    path: '/',
    name: "app-root",
    meta: {
      action: "read",
      resource: "AppRoot",
      isPublicRoute: true,
      hasMaintenanceMode: true,
      layout: 'horizontal',
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/user/public/home'),
  },
  {
    path: '/rentals',
    name: "rentals",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      hasMaintenanceMode: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/user/public/rentals'),
  },
  {
    path: '/rentals/:id/more-info',
    name: "rental-details",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      hasMaintenanceMode: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/user/public/rentals/details'),
  },
  {
    path: '/about-us',
    name: "about-us",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      hasMaintenanceMode: true,
      resource: "PublicRoute",
      // redirectIfLoggedIn: true,
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/user/public/about-us'),
  },
  {
    path: '/oauth/google',
    name: 'google-oauth',
    component: () => import('@/pages/user/auth/oauth/google.vue'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["user"],
      redirectIfLoggedIn: false
    },
  },
  {
    path: '/oauth/apple',
    name: 'apple-oauth',
    component: () => import('@/pages/user/auth/oauth/apple.vue'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["user"],
      redirectIfLoggedIn: false
    },
  },
  {
    path: '/terms',
    name: "terms",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      hasMaintenanceMode: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/user/public/terms'),
  },
  {
    path: '/privacy',
    name: "privacy",
    meta: {
      action: "read",
      layout: 'horizontal',
      isPublicRoute: true,
      hasMaintenanceMode: true,
      resource: "PublicRoute",
      contentClass: 'm-0 pl-0 pr-0 pt-5x',
    },
    component: () => import('@/pages/user/public/privacy'),
  },
]
