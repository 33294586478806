export default class DashboardJwtService {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchDashboardStatsData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/stats`, {
      params
    })
  }

  fetchDashboardGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/graph`, {
      params
    })
  }

  fetchDashboardSignUpsGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/signups-graph`, {
      params
    })
  }

  fetchDashboardDailyReservationsGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminDashboardEndpoint}/daily-reservations-graph`, {
      params
    })
  }
}
