import { get } from "lodash";

import store from "@/store"
import MFAJwtServices from "@core/api/services/mfa";
import AuthJwtServices from "@/@core/api/services/auth";
import RisticJwtServices from "@/@core/api/services/ristic";
import MainJwtServices from "@/@core/api/services/admin/index";
import AdminAuthJwtServices from "@/@core/api/services/admin/adminAuth";

import jwtDefaultConfig from './jwtDefaultConfig'


export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = [];

  // Services;
  authService = null;

  adminAuthService = null;

  mfaService = null;

  adminService = null;

  userService = null;

  sharedService = null;

  checkInService = null;

  ristic = null;

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    this.mfaService = new MFAJwtServices(this.axiosIns, this.jwtConfig);

    this.authService = new AuthJwtServices(this.axiosIns, this.jwtConfig);

    this.adminService = new MainJwtServices(this.axiosIns, this.jwtConfig);

    this.adminAuthService = new AdminAuthJwtServices(this.axiosIns, this.jwtConfig);

    this.ristic = new RisticJwtServices(this.axiosIns, this.jwtConfig);

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // eslint-disable-next-line no-undef
        NProgress.start();
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, true)

        // Get token from localStorage
        const accessToken = this.authService.getAccessToken();
        const hasCustomToken = !!get(config, `headers.Authorization`);

        if (!hasCustomToken && accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => {
        // eslint-disable-next-line no-undef
        NProgress.done();
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, false)



        Promise.reject(error)
      }
    );

    this.axiosIns.interceptors.response.use(
      response => {
        // eslint-disable-next-line no-undef
        NProgress.done()
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, false)
        return response;
      },
      (error) => {
        const invalidTokenErrors = ["invalid token", "invalid access token.", "invalid signature", "invalid or expired token."];

        const errorMessage = get(error, "response.data.message", "").toLowerCase()
        const isInvalidTokenError = invalidTokenErrors.some(message => errorMessage.includes(message));

        if (error && error.response && [403, 500].includes(error.response.status) && isInvalidTokenError) {
          this.authService.clearAccessToken();
        }
        // eslint-disable-next-line no-undef
        NProgress.done();
        store.commit(`navigation/UPDATE_FETCHING_REMOTE_DATA`, false)
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }
}
