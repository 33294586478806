import mfaEndpoints from "./endpoints/mfa";
import authEndpoints from "./endpoints/auth";
import userEndpoints from "./endpoints/user";
import adminEndpoints from "./endpoints/admin";
import adminAuthEndpoints from "./endpoints/adminAuth";

export default {
  // Endpoints
  mfa: mfaEndpoints,
  auth: authEndpoints,
  adminAuth: adminAuthEndpoints,

  user: userEndpoints,
  admin: adminEndpoints,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageMFATokenKeyName: 'mfaAccessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageConfirmPhoneToken: 'confirmPhoneToken',
  storageMFADeactivationToken: 'mfaDeactivationToken',
  storageConfirmPhoneTimestamp: 'confirmPhoneTimestamp',
  storageMFAPasswordResetToken: 'mfaPasswordResetToken',
  storageConfirmAccountTokenKeyName: 'confirmAccountToken',
  storagePasswordResetTokenKeyName: 'passwordResetToken',
}
