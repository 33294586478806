export default class ReportedProblemsJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get("reported-problems", { params });
  }

  add(payload = {}) {
    return this.axiosIns.post(`reported-problems`, payload);
  }
}
