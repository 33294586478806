import CarsJwtServices from "./cars";
import BestOfferJwtServices from "./best-offer";
import CarBookingJwtServices from "./car-bookings";
import FavoriteCarsJwtServices from "./favorite-cars";
import MiscellaneousJwtServices from "./miscellaneous";
import ContactEmailsJwtServices from "./contact-emails";
import CarViewHistoryJwtServices from "./car-view-history";
import ReportedProblemsJwtServices from "./reported-problems";

export default class RisticJwtServices {
  axiosIns = null;

  jwtConfig = null;

  miscellaneous = null;

  cars = null;

  favoriteCars = null;

  carsViewedHistory = null;

  contactEmails = null;

  car_bookings = null;

  bestOffers = null;

  reportedProblems = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
    this.miscellaneous = new MiscellaneousJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
    this.cars = new CarsJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
    this.favoriteCars = new FavoriteCarsJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
    this.carsViewedHistory = new CarViewHistoryJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
    this.contactEmails = new ContactEmailsJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
    this.car_bookings = new CarBookingJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
    this.bestOffers = new BestOfferJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
    this.reportedProblems = new ReportedProblemsJwtServices(
      this.axiosIns,
      this.jwtConfig
    );
  }
}
