import useJwt from '@/auth/jwt/useJwt'
import store from "@/store"
// import Swal from "sweetalert2";

import { get } from "lodash"
import {
  MUTATE_SETTINGS,
} from "@/store/config/mutation-keys"


export function isExplicitDenied(authorization, urn, actions) {
  return authorization.special_deny.filter(statement => statement.resource === urn).some(entry => actions.every(action => entry.actions.includes(action)));
}

export function isExplicitAllowed(authorization, urn, actions) {
  return authorization.special_allow.filter(statement => statement.resource === urn).some(entry => actions.every(action => entry.actions.includes(action)));
}

export const canNavigate = (to) => {
  const { authorization } = store.getters['auth/userData'];
  return to && to.matched && to.matched.some(route => {
    if (route.meta.permissions) {
      const { permissions = [] } = route.meta;
      const permissionsToEvaluate = !Array.isArray(permissions) ? [permissions] : permissions;
      if (permissionsToEvaluate.length === 0) { return true }

      const { resource } = to.meta;
      if (to.params.id && resource) {
        const urn = `urn:ristic:${resource}:${to.params.id}`;

        if (isExplicitDenied(authorization, urn, permissions)) {
          return false;
        }

        if (isExplicitAllowed(authorization, urn, permissions)) {
          return true
        }
      }

      return permissionsToEvaluate.every(permission => authorization.grants.includes(permission));
    }
    return true;
  })
}

export const _ = undefined


export const checkLoginTokenStatus = async () => {
  const jsonWebTokenValidityResponse = await useJwt.authService.confirmTokenValidity("web_token");
  const { authorized, user, settings, client_group } = get(jsonWebTokenValidityResponse, 'data.data', {});

  return { authorized, user, settings, client_group };
}

export const getSignUpSettings = async () => {
  try {
    const response = await useJwt
      .authService
      .getRegistrationSettings();
    const { sign_ups_disabled, password_complexity_settings } = response.data.data;
    store.commit(`auth/${MUTATE_SETTINGS}`, { password_complexity_settings });
    return sign_ups_disabled;
  } catch (error) {
    return false
  }
}

export const getMaintenanceSettings = async () => {
  try {
    const response = await useJwt
      .authService
      .getRegistrationSettings();
    const { enable_maintenance_mode } = response.data.data;
    return enable_maintenance_mode;
  } catch (error) {
    return false
  }
}

export const getSystemMessageSettings = async () => {
  try {
    const response = await useJwt
      .authService
      .getRegistrationSettings();
    const { system_message_settings } = response.data.data;
    store.commit(`auth/${MUTATE_SETTINGS}`, { system_message_settings });
    return system_message_settings;
  } catch (error) {
    return false
  }
}
