export default class AuditTrailServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminAuditTrail, {
      params
    })
  }

  export(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminAuditTrail}/export`, {
      params,
      responseType: "blob"
    })
  }

  listUpdated(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminLogsEndpoint, {
      params
    })
  }

  exportUpdated(params) {
    return this.axiosIns.get(this.jwtConfig.admin, {
      params,
      responseType: "blob"
    })
  }
}
