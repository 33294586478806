
import maskdata from "maskdata";
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { get, set, startCase } from "lodash";
import { useWindowScroll } from '@vueuse/core'
import { initialAbility } from '@/libs/acl/config'
import { avatarText, formatDateToMonthShort } from '@core/utils/filter'
import { formatMoney, formatDate, formatDateDistance, formatTime, formatDuration, captureMongoId } from "@/@core/utils/utils";

import { MUTATE_LOGIN_STATUS, MUTATE_USER_DATA } from "@/store/config/mutation-keys"

export default {
  data() {
    return {
      avatarText,
      yesNoOptions: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
      transactionStatusOptions: [
        { label: "Pending", value: "pending" },
        { label: "Success", value: "success" },
        { label: "Failed", value: "failed" },
        { label: "Cancelled", value: "cancelled" },
      ]
    }
  },
  computed: {
    carRentalSettings() {
      return get(this.generalAppSettings, 'car_rental_settings', {});
    },
    enableRefunds() {
      return get(this.carRentalSettings, 'enable_refunds', false);
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    userType() {
      const user_type = this.getValueFromSource(this.currentUser, 'user_type');
      return user_type
    },
    userName() {
      const full_name = this.getValueFromSource(this.currentUser, 'full_name');
      return this.sentenceCase(full_name);
    },
    userAvatar() {
      const image_path = this.getValueFromSource(this.currentUser, 'avatar.path');
      return image_path
    },
    generalAppSettings() {
      return get(this.$store, 'getters[auth/settings]', {});
    },
    refundWindowText() {
      const { full_refund_window } = this.carRentalSettings
      if (!full_refund_window) { return "" }

      if (full_refund_window < 1) {
        return `${full_refund_window * 60} minutes`
      }
      return `${full_refund_window} hours`
    },
    termsAndConditions() {
      return get(this.generalAppSettings, 'car_rental_settings.terms_and_conditions', {});
    },
    openingTime() {
      return get(this.carRentalSettings, 'opening_time', '');
    },
    openingHour() {
      if (!this.openingTime) return 0;
      const [hour, ] = this.openingTime.split(":")
      const parsedHour = parseInt(hour)
      return isNaN(parsedHour) ? 0 : parsedHour
    },
    closingTime() {
      return get(this.carRentalSettings, 'closing_time', '');
    },
    closingHour() {
      if (!this.closingTime) return 0;
      const [hour, ] = this.closingTime.split(":")
      console.log("closing - hour", hour)
      const parsedHour = parseInt(hour)
      return isNaN(parsedHour) ? 0 : parsedHour
    },
    isLoggedIn() {
      return this.$store.getters[`auth/isLoggedIn`]
    },
    currentUser() {
      return get(this.$store, 'getters[auth/userData]', {});
    },
    currentUserRole() {
      return get(this.currentUser, 'user_type', '');
    },
    isCurrentUserAdmin() {
      return this.currentUserRole === "admin"
    },
    adminGrants() {
      return this.getValueFromSource(this.currentUser, 'authorization.grants', []);
    },
    adminSpecialDenials() {
      return this.getValueFromSource(this.currentUser, 'authorization.special_deny', []);
    },
    onlineUsers() {
      return get(this.$store, 'getters[auth/onlineUsers]', []);
    },
    graphMonthOptions() {
      const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'
      ]

      return months.map((month, index) => {
        return {
          label: month,
          value: index,
        }
      })
    },
    graphYearOptions() {
      const thisYear = new Date().getFullYear()
      const graphYearOptions = [];

      for (let yearOption = thisYear - 5; yearOption < thisYear; yearOption += 1) {
        graphYearOptions.unshift(yearOption);
      }

      graphYearOptions.unshift(thisYear);
      return graphYearOptions
    },
  },
  methods: {
    startCase,
    formatTime,
    formatDuration,
    captureMongoId,
    formatDateToMonthShort,
    getUserName(user) {
      if (user.name) { return user.name }
      
      const full_name = this.getValueFromSource(user, 'full_name', '');
      if (full_name) {
        return full_name
      }

      const { first_name, last_name } = user;
      return `${first_name} ${last_name}`
    },
    getAlertVariant(booking) {
      if (booking.status === "cancelled") {
        return "danger";
      }
      if (booking.status === "completed") {
        return "success";
      }
      if (booking.status === "pending") {
        return "warning";
      }
      return "info";
    },
    decodeJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
          .join('')
      );
      return JSON.parse(jsonPayload);
    },
    generateGoogleAuthNonce() {
      const randomString = btoa(this.generateRandomString())
      sessionStorage.setItem('googleAuthNonce', randomString);
      return randomString;
    },
    generateRandomString(length = 16) {
      const array = new Uint8Array(length);
      crypto.getRandomValues(array);
      return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
    },
    generateState(clientId, redirectUri) {
      const randomString = this.generateRandomString()

      const combinedString = `${clientId}:${redirectUri}:${randomString}`;

      const state = btoa(combinedString);
      sessionStorage.setItem('oauthState', state);
      return state;
    },
    verifyOauthState() {
      const baseUrl = new URL(window.location)
      const returnedState = new URLSearchParams(baseUrl.hash).get('#state')

      const originalState = sessionStorage.getItem('oauthState');

      return originalState === returnedState;
    },
    verifyGoogleOauthNonce() {
      const baseUrl = new URL(window.location)
      const id_token = new URLSearchParams(baseUrl.hash).get('id_token')

      const decoded_id_token = this.decodeJwt(id_token)

      const originalNonce = sessionStorage.getItem('googleAuthNonce');
      return originalNonce === decoded_id_token.nonce;
    },
    notEmpty(value) {
      return value !== undefined && value !== null && value !== ''
    },
    getFirstCarImage(car) {
      const car_images = this.getValueFromSource(car, 'car_images', []) || [];
      const firstValidImage = car_images.find(this.notEmpty);
      return firstValidImage ? firstValidImage.image.path : "";
    },
    canViewVerticalNavMenuLink(item) {
      return item.permissions ? item.permissions.every(permission => {
        return this.adminGrants.includes(permission);
      }) : true;
    },
    canViewVerticalNavMenuGroup(group) {
      return group.children ? group.children.some(child => {
        return this.canViewVerticalNavMenuLink(child);
      }) : true;
    },
    canViewVerticalNavMenuHeader(header) {
      return header.permissions ? header.permissions.some(permission => {
        return this.adminGrants.includes(permission);
      }) : true;
    },
    can(actions) {
      const actionsToEval = !Array.isArray(actions) ? [actions] : actions;
      return actionsToEval.every(action => this.adminGrants.includes(action));
    },
    canView(record) {
      let matchingStatements = []
      const { urn } = record;
      if (urn) {
        const urn_components = urn.split(":")
        const resource = urn_components[2]
        if (resource) {
          const action = `${resource}:CanView`
          matchingStatements = this.adminSpecialDenials.filter(statement => statement.actions.includes(action))
        }
      }
      return matchingStatements.filter(statement => statement.resource === record.urn).length === 0
    },
    playSound(filename) {
      const mp3Source = '<source src="/' + filename + '.mp3" type="audio/mpeg">';
      const oggSource = '<source src="/' + filename + '.ogg" type="audio/ogg">';
      const embedSource = '<embed hidden="true" autostart="true" loop="false" src="/' + filename + '.mp3">';
      document.getElementById("sound-source").innerHTML = '<audio autoplay="autoplay">' + mp3Source + oggSource + embedSource + '</audio>';
    },
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    setValue: set,
    maskPhoneNumber(phone) {
      const options = {
        maskWith: "*",
        unmaskedStartDigits: 3,
        unmaskedEndDigits: 3
      }
      return maskdata.maskPhone(phone, options);
    },
    maskEmail(email) {
      if (!email) return "";
      const options = {
        maskWith: "*",
        unmaskedStartCharactersBeforeAt: 3,
        unmaskedEndCharactersAfterAt: 4,
        maskAtTheRate: false
      }
      return maskdata.maskEmail2(email, options);
    },
    getActualValueFromSource(source, path, defaultValue = "") {
      const value = get(source, path, defaultValue);
      if (value) return value;
      return defaultValue;
    },
    generateRandomID(length = 6) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    formatMoney(money, fraction = 2) {
      if (!money) {
        return 0.00
      }
      return formatMoney(money, fraction);
    },
    formatDate(date, formatStr = "dd-MM-yyyy") {
      if (!date) {
        return ""
      }
      return formatDate(date, formatStr);
    },
    capitalizeFirstWord(text) {
      if (!text) {
        return text;
      }
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    sentenceCase(text) {
      if (!text) {
        return text;
      }
      let sentenceCase = "";
      const splitText = text.split(" ");
      splitText.forEach(txt => {
        sentenceCase += txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase() + ' ';
      });
      return sentenceCase
    },
    formatDateDistance(date) {
      if (!date) {
        return ""
      }
      return formatDateDistance(date)
    },
    getValidationState({ dirty, validated, required: fieldRequired, changed, valid = null }) {
      const result = dirty || validated ? valid : null
      return !fieldRequired && !changed ? null : result
    },
    moveArrayElement(array, fromIndex, toIndex) {
      const element = array[fromIndex];
      array.splice(fromIndex, 1);
      array.splice(toIndex, 0, element);
      return array;
    },
    resolveStatusVariant(status) {
      const secondaryVariants = [
        'draft'
      ];

      const warningVariants = [
        'unread',
        'pending',
        'flagged',
        'admin-approve',
        'pending-product-delivery',
        'inactive',
        'attention',
        "pending_confirmation",
        "pending_email",
        "pending_sms",
        "pending_docs_verification",
        'medium',
        "submitted"
      ];

      const successVariants = [
        'read',
        'published',
        'approved',
        'paid',
        'active',
        'open',
        'delivered',
        'confirmed',
        'low',
        'completed',
        "active",
        'success'
      ];

      const infoVariants = [
      ];

      const dangerVariants = [
        'archived',
        'deleted',
        'cancelled',
        'rejected',
        'declined',
        'not_fulfilled',
        'banned',
        'high',
        'failed',
        'cancelled',
        'closed',
        'expired',
        'overdue',
        'loss',
        'error',
        'danger',
        'inactive',
        'revoked',
        false
      ];

      if (secondaryVariants.includes(status)) return 'secondary';
      if (warningVariants.includes(status)) return 'warning';
      if (successVariants.includes(status)) return 'success';
      if (infoVariants.includes(status)) return 'info';
      if (dangerVariants.includes(status)) return 'danger';

      return 'primary';
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'primary'
      if (role === 'vendor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    },
    resolveUserRoleIcon(role) {
      if (role === 'user') return 'UserIcon'
      if (role === 'vendor') return 'ShoppingCartIcon'
      if (role === 'admin') return 'ServerIcon'
      return 'UserIcon'
    },
    getUserStatusText(status) {
      if (status === "pending_confirmation") {
        return "Pending"
      }
      return startCase(status)
    },
    extractTextContentFromHTML(s) {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    kFormatter(num) {
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    },
    useJwt() {
      return useJwt
    },
    displaySweetError(error_message, title = "Error!") {
      this.$swal({
        title,
        text: error_message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    showError(error) {
      const error_message = get(error, "response.data.message") || error.message;
      console.log("error_message", error_message);
      this.error_message = error_message;
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error!",
          icon: "AlertTriangleIcon",
          variant: "danger",
          text: error_message,
        },
      });
    },
    isUserOnline(user_id) {
      const is_user_online = this.onlineUsers.find(online_user_id => online_user_id === user_id);
      return !!is_user_online
    },
    isCurrentUserOnline() {
      const is_user_online = this.onlineUsers.find(online_user_id => online_user_id === this.currentUser._id);
      return !!is_user_online
    },
    waitForSomeMilliseconds(ms) {
      return new Promise(resolve => {
        setTimeout(() => resolve(), ms)
      })
    },
    getWaitTimeExp(retryCount) {
      if (retryCount === 0) {
        return 0;
      }

      // eslint-disable-next-line no-restricted-properties
      const waitTime = (Math.pow(2, retryCount) * 100);
      return waitTime;
    },
    initializeSocketIO() {
      //* initialize Socket.io
      // if (this.$socket.disconnected) {
      //   const socket_token = this.useJwt().authService.getAccessToken();
      //   this.$socket.client.io.opts.query = { token: socket_token };
      //   this.$socket.client.open();
      //   console.log("[SOCKET CONNECTED]")
      // } else {
      //   console.log("[SOCKET ALREADY CONNECTED]")
      // }
    },
    yesOrNo(boolean_value) {
      return boolean_value ? 'Yes' : 'No'
    },
    convertFloorNumberToActualName(floor_number) {
      const floor_number_options = [
        { label: "Ground Floor", value: 0 },
        { label: "First Floor", value: 1 },
        { label: "Second Floor", value: 2 },
        { label: "Third Floor", value: 3 },
        { label: "Fourth Floor", value: 4 },
        { label: "Fifth Floor", value: 5 },
      ]
      const floor_number_index = floor_number_options.findIndex(i => i.value === floor_number)
      if (floor_number_index >= 0) {
        return floor_number_options[floor_number_index].label
      }
      return "N/A"
    },
    base64toBlob(data) {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

      const bytes = atob(base64WithoutPrefix);
      // eslint-disable-next-line prefer-destructuring
      let length = bytes.length;
      const out = new Uint8Array(length);

      // eslint-disable-next-line no-plusplus
      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: 'application/pdf' });
    },
    redirectToLastPage() {
      let last_page = sessionStorage.getItem("oauthLastPage");
      this.$store.commit("ristic/RESET_AUTH_UI_STATE");
      if (last_page.includes("more-info")) {
        const url = new URL(last_page);
        url.searchParams.append("rent-now", "true");
        last_page = url.toString();
      }
      window.location.href = last_page;
    },
    async retrySocketConnectionWithExponentialBackoff(depth = 0) {
      if (this.$socket.client.disconnected) {
        if (depth > 10) {
          // if its still disconnected, then something is wrong.
          // throw an error
          throw new Error("There was an issue communicating to the server. Please try refreshing the page.")
        }

        this.initializeSocketIO();

        const waitTimeInMs = this.getWaitTimeExp(depth);
        await this.waitForSomeMilliseconds(waitTimeInMs);
        return this.retrySocketConnectionWithExponentialBackoff(depth + 1);
      }
      return true;
    },
    async logout() {
      try {
        await this.useJwt().authService.logout();
      } finally {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to home page
        const redirect_route_name = 'app-root';

        sessionStorage.setItem('last_page_accessed', this.$route.path);

        this.$router.replace({ name: redirect_route_name })
          .finally(() => {
            this.$store.commit(`auth/${MUTATE_LOGIN_STATUS}`, false)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Logout Success`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: `You have successfully logged out.`,
              },
            })
          })
      }
    },
    async loginWithApple(payload) {
      try {
        console.log("APPLE LOGIN", payload)
        this.loading = true;

        const response = await this.useJwt().authService.loginWithApple(payload);

        const response_data = response.data.data;
        const { mfa_auth_required } = response_data;

        if (mfa_auth_required) {
          const { mfa_access_token, mfa_method } = response_data;
          this.useJwt().mfaService.setMfaToken(mfa_access_token);

          this.$router
            .push({
              name: "security-mfa-authentication",
              params: { mfa_method },
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Set-up multi factor authentication",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: "Kindly secure your account",
                },
              });
            })
            .catch(() => {});
        } else {
          const { access_token, user } = response_data;
          this.useJwt().authService.setToken(access_token);
          this.$store.commit(`auth/${MUTATE_LOGIN_STATUS}`, true);
          this.$store.commit(`auth/${MUTATE_USER_DATA}`, user);

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Welcome ${user.first_name || user.last_name}`,
              icon: "CheckCircleIcon",
              variant: "success",
              text: `You have successfully logged in.`,
            },
          });
          this.redirectToLastPage();
        }
      } catch (error) {
        console.log(error);
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.error = error_message;
      } finally {
        this.loading = false;
      }
    },
    async initiatePasswordReset(passwordResetToken) {
      if (!passwordResetToken) {
        this.showError(new Error("Password reset token is missing"))
        return
      }
      try {
        this.useJwt().authService.setPasswordResetToken(passwordResetToken);
        await this.useJwt().authService.confirmTokenValidity("password_reset_token");

        this.$store.commit("ristic/SET_AUTH_UI_STATE", { key: 'passwordReset', value: true });
      } catch (error) {
        this.showError(new Error("Password reset token is expired or invalid. Request a new one."))
      }
    },
  },
  setup() {
    const { y } = useWindowScroll()

    const scrollToTop = () => {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    return { y, scrollToTop }
  },
}

export const _ = null
