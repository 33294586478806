export default class UserRolesJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetch(user_role_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`)
  }

  list(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role`, {
      params
    })
  }

  create(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role`, payload);
  }

  update(user_role_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`, payload)
  }

  delete(user_role_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminPermissionsEndpoint}/user-role/${user_role_id}`)
  }
}
