export default class favoriteCarsJwtServices {
  axiosIns = null;

  jwtConfig = null;

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  favoriteCarIds() {
    return this.axiosIns.get("favorite-cars/car-ids", {});
  }

  list() {
    return this.axiosIns.get("favorite-cars", {});
  }

  add(id) {
    return this.axiosIns.put(`favorite-cars/${id}`);
  }

  remove(id) {
    return this.axiosIns.delete(`favorite-cars/${id}`);
  }
}
