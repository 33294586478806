import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isHorizontalMobileMenuCollapsed: $themeConfig.layout.menu.isHorizontalMobileMenuCollapsed,
    tags: {
      pending: 0,
      disburse: 0
    }
  },
  getters: {},
  mutations: {
    UPDATE_HORIZONTAL_MOBILE_MENU_COLLAPSED(state, val) {
      state.isHorizontalMobileMenuCollapsed = val
    },
    UPDATE_TAGS(state, val) {
      state.tags = val
    }
  },
  actions: {},
}
