export default {
  // Admin Endpoints
  adminCarsEndpoint: '/cars',
  adminLogsEndpoint: '/logs',
  usersEndpoint: '/users',
  adminCustomersEndpoint: '/customers',
  adminBlogsEndpoint: '/blogs',
  adminAPIEndpoint: '/api-keys',
  adminSearchEndpoint: '/search',
  adminStoriesEndpoint: '/story',
  adminAuditTrail: '/audit-trail',
  adminDriversEndpoint: '/drivers',
  adminSettingsEndpoint: '/settings',
  adminPoliciesEndpoint: '/policies',
  adminCarTypesEndpoint: '/car-types',
  adminCarMakesEndpoint: '/car-makes',
  adminDashboardEndpoint: '/dashboard',
  adminCarBrandsEndpoint: '/car-brands',
  adminCarImagesEndpoint: '/car-images',
  adminRefundRequest: '/refund-requests',
  adminCsvUploadsEndpoint: '/csv-uploads',
  adminCarPricingEndpoint: '/car-pricing',
  adminPermissionsEndpoint: '/permissions',
  adminCarFeaturesEndpoint: '/car-features',
  adminCarBookingsEndpoint: '/car-bookings',
  adminContactEmailsEndpoint: 'contact-emails',
  adminSampleCSVFileEndpoint: '/sample-csv-file',
  adminReportedProblemsEndpoint: '/reported-problems',
  adminBookingLocationsEndpoint: '/booking-locations',
  adminSendgridTemplatesEndpoint: '/sendgrid/templates',
  adminExportTemplatesEndpoint: "/csv/exports/templates",
}
