export default class CustomersJwtService {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  // /** Users */

  updateUser(user_id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/update`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  deactivateUser(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/deactivate`, {})
  }

  approveUser(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/approve`, {})
  }

  deleteUserAvatar(user_id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/avatar`, {})
  }

  resendAccountConfirmationEmail(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/account/confirmation/resend`)
  }

  resetAdminUserMFA(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.usersEndpoint}/${user_id}/account/mfa/reset`)
  }

  resetUserMFA(user_id) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}/account/mfa/reset`)
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminCustomersEndpoint, {
      params
    })
  }

  fetchUser(user_id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCustomersEndpoint}/${user_id}`, {})
  }

  export(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminCustomersEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  import(formData) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminCustomersEndpoint}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
}
