export default class AdminContactEmails {
  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminContactEmailsEndpoint, {
      params
    })
  }

  export(params) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminContactEmailsEndpoint}/export/all`, {
      params,
      responseType: "blob"
    })
  }

  markAsRead(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminContactEmailsEndpoint}/${id}/mark-as-read`, {})
  }

  markAsUnread(id) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminContactEmailsEndpoint}/${id}/mark-as-unread`, {})
  }

  delete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminContactEmailsEndpoint}/${id}`, {})
  }
}
