export default class SampleCSVFilesJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  // Sample CSV Endpoints
  create(payload) {
    return this.axiosIns.post(`${this.jwtConfig.admin.adminSampleCSVFileEndpoint}`, payload)
  }

  list(params) {
    return this.axiosIns.get(this.jwtConfig.admin.adminSampleCSVFileEndpoint, {
      params
    })
  }

  fetch(id) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminSampleCSVFileEndpoint}/${id}`, {})
  }

  update(id, formData) {
    return this.axiosIns.put(`${this.jwtConfig.admin.adminSampleCSVFileEndpoint}/${id}`, formData);
  }

  delete(id) {
    return this.axiosIns.delete(`${this.jwtConfig.admin.adminSampleCSVFileEndpoint}/${id}`, {})
  }

  download(reference) {
    return this.axiosIns.get(`${this.jwtConfig.admin.adminSampleCSVFileEndpoint}/${reference}/download`)
  }
}
