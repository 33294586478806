export default {
  // Auth Endpoints
  logsEndpoint: '/auth/logs',
  loginEndpoint: '/auth/login',
  logoutEndpoint: '/auth/logout',
  registerEndpoint: '/auth/register',
  registrationSettingsEndpoint: '/auth/registration/settings',

  confirmTokenValidityEndpoint: '/auth/check',
  confirmEmailEndpoint: '/auth/email/confirm',
  confirmPhoneEndpoint: '/auth/phone/confirm',
  resendConfirmEmailEndpoint: '/auth/email/confirmation/resend',
  requestPhoneVerificationEndpoint: '/auth/phone/confirmation/request',

  changePasswordEndpoint: '/auth/password/change',
  resetPasswordEndpoint: '/auth/password/reset/change',
  requestPasswordResetEndpoint: '/auth/password/reset/request',

  checkPasswordResetValidityEndpoint: '/auth/check-password-reset-token',
  onboardingEndpoint: '/auth/onboarding',
  updateUserProfileEndpoint: '/auth/profile',
  completeOnboardEndpoint: '/auth/onboard',
  userNotificationsEndpoint: '/auth/notifications',

}
